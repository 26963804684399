import { AUTH_API } from "../../config";
import { get } from "lodash";
import { replaceVars } from "../../util/replaceVars";

// https://stackoverflow.com/a/30106551
function b64EncodeUnicode(str) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(
      /%([0-9A-F]{2})/g,
      function toSolidBytes(match, p1) {
        return String.fromCharCode("0x" + p1);
      },
    ),
  );
}

export const save = async (file, token, sha) => {
  if (get(file, "data.extend.github.active")) {
    const url = file.data.extend.github.url;
    const owner = "signalwerk";
    const repo = "documents";
    const file_path = `docs/data/docs/${url}`;
    const path = `/repos/${owner}/${repo}/contents/${file_path}/data.json`;
    const message = "written by notzer";

    let contentSHA = sha;

    // const content = window.btoa(file.data.content);

    const content = replaceVars(file.data.content);

    const tree = `/repos/${owner}/${repo}/git/trees/main:${encodeURIComponent(
      file_path,
    )}`;

    // we didn't get a sha, so we need to get the current tree
    if (!contentSHA) {
      contentSHA = await fetch(`https://api.github.com${tree}`, {
        headers: {
          Accept: "application/vnd.github.v3+json",
          Authorization: `token ${token}`,
          // "Content-Type": "application/json",
        },
      })
        .then(function (res) {
          return res.json();
        })
        .then(function (data) {
          console.log("------ sha info", data);
          return get(data, "tree[0].sha");
        })
        .catch(function (res) {
          console.log(res);
        });
      console.log("------ sha info", contentSHA);
    }

    // Syntax: GET /repos/<owner>/<repo>/git/trees/url_encode(<branch_name>:<parent_path>)
    // Example: https://api.github.com/repos/libgit2/libgit2sharp/git/trees/master:Lib%2FMoQ

    return fetch(`https://api.github.com${path}`, {
      headers: {
        Accept: "application/vnd.github.v3+json",
        Authorization: `token ${token}`,
        // "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify({
        message,
        content: b64EncodeUnicode(`\n${content}`),
        sha: contentSHA,
      }),
    })
      .then(function (res) {
        return res.json();
      })
      .catch(function (res) {
        console.log(res);
      });
  }
};

export const getOAuthToken = async (code, state) => {
  const response = await fetch(`${AUTH_API}/auth/`, {
    headers: {
      Accept: "application/json",
    },
    method: "POST",
    body: JSON.stringify({ code, state }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  return await response.json();
};
