import { parse } from "../../model/file.js";
import { API_DATA } from "../../config";

const handle = (promise) => {
  return promise
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response);
      } else {
        var error = new Error(response.statusText || response.status);
        error.response = response;
        return Promise.reject(error);
      }
    })
    .then(async (response) => {
      const value = await response.json();
      return [value, undefined];
    })
    .catch((error) => Promise.resolve([undefined, error]));
};

export const index = async (headers) => {
  const response = await handle(
    fetch(`${API_DATA}/contents/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
    })
  );
  return response;
};

export const add = async (data, headers) => {
  return await handle(
    fetch(`${API_DATA}/contents/`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      body: JSON.stringify(parse(data)),
    })
  );
};

export const save = async (data, headers) => {
  return await handle(
    fetch(`${API_DATA}/contents/${data.id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      body: JSON.stringify(parse(data)),
    })
  );
};

export const get = async (id, version, headers) => {
  return await handle(
    fetch(`${API_DATA}/contents/${id}/${version}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
    })
  );
};

export const history = async (id, headers) => {
  return await handle(
    fetch(`${API_DATA}/contents/${id}/history`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
    })
  );
};
