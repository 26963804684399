import React from "react";

const componentRegistry = {};

export const registerComponent = (eventName, component) => {
  if (!componentRegistry[eventName]) {
    componentRegistry[eventName] = [];
  }
  componentRegistry[eventName].push(component);
};

export const getComponents = (eventName) => componentRegistry[eventName] || [];

export const PluginComponents = ({ eventName, ...props }) => {
  const components = getComponents(eventName);

  return components.map((Component, index) => (
    <Component key={index} {...props} />
  ));
};
