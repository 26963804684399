// import Plugins from "../plugin";

import { mergeText } from "../../src/util/mergeText.js";

const Keys = ["fav", "github"];

export const parse = (value) => {
  if (!value.id) {
    throw new Error("Thre has to be an id to store!");
  }

  let data = {
    id: value.id,
    version: value.version,
    data: {
      content: value.data.content,
    },
    modified: Date.now(),
    deleted: value.deleted,
  };

  if (value.data.extend) {
    data.data.extend = {};

    Keys.forEach((key) => (data.data.extend[key] = value.data.extend[key]));
  }

  return data;
};

export const update = (baseVersion, nextVersion, newVersion) => {
  const finalContent = mergeText(
    baseVersion.data.content,
    nextVersion.data.content,
    newVersion.data.content
  );

  const file = {
    id: baseVersion.id,
    version: baseVersion.version + 1,
    data: {
      content: finalContent,
      extend: newVersion.extend,
    },
    modified: newVersion.modified,
    deleted: newVersion.deleted,
    user: newVersion.user,
  };

  return file;
};

export const isEqual = (a, b) => {
  if (a.id !== b.id) {
    return false;
  }

  if (a.data.content !== b.data.content) {
    return false;
  }

  if (a.data.extend || b.data.extend) {
    console.log(".... check extend", a.data.extend, b.data.extend);

    for (let i = 0; i < Keys.length; i++) {
      const key = Keys[i];
      console.log("---- plugin A", key);

      // one has no extend
      if (!a.data.extend || !b.data.extend) {
        return false;
      }

      console.log("---- plugin B", key);
      console.log("---- plugin c", a.data.extend[key], b.data.extend[key]);

      // does the key is same on both
      if (a.data.extend[key] !== b.data.extend[key]) {
        return false;
      }
    }
  }

  if (a.deleted !== b.deleted) {
    return false;
  }

  console.log("--- is equal");
  return true;
};
