// https://github.com/gr2m/cloudflare-worker-github-oauth-login
// https://dash.cloudflare.com/cccc47b31d4d3de550ef4a9b3796fdd4/workers/edit/auth

// url = `https://github.com/login/oauth/authorize`,
// id = 'github-oauth-authorize'
import { useContext, useState } from "react";

import OauthHandler from "../OauthHandler";
import { LoginGithubContext } from "../../../store/loginGithub";
import { getOAuthToken } from "../../../api/Github";
import { GITHUB_CLIENT_ID, GITHUB_REDIRECT } from "../../../config";

export const LoginGitHubProvider = () => {
  const [, stateGithubDispatch] = useContext(LoginGithubContext);

  const [error, setError] = useState(null);

  const authState = encodeURIComponent(
    JSON.stringify({ redirect: window.location.href }),
  );

  const URL = `https://github.com/login/oauth/authorize?client_id=${GITHUB_CLIENT_ID}&scope=repo&redirect_uri=${GITHUB_REDIRECT}/redirect&scope=repo&state=${authState}`;

  const onCode = async (code, state) => {
    try {
      const value = await getOAuthToken(code, state);
      stateGithubDispatch({ type: "setGithubOAuthToken", value });
      setError(null); // Clear any previous errors
    } catch (error) {
      setError(`Login failed: ${error.message}`);
    }
  };

  const onClose = () => console.log("Login closed");

  return (
    <div>
      <OauthHandler url={URL} onCode={onCode} onClose={onClose}>
        Login with Github
      </OauthHandler>

      {error && <div className="error-message">{error}</div>}
    </div>
  );
};
