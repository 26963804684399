import { useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { printYYYYMMDD } from "../../util/date";
import { FilesContext } from "../../store/files";
import Button from "../Button";
import MarkdownEditor from "./MarkdownEditor";
import { PluginComponents } from "../../extensions/componentRegistry";
import "./index.css";

const Editor = ({ id }) => {
  const [state, stateDispatch] = useContext(FilesContext);

  // const inputEl = useRef(null);
  let navigate = useNavigate();

  const file = state[id];

  if (!file || file.deleted) return <Navigate to="/files" />;

  const handleChange = (val) => {
    if (file.data.content !== val) {
      stateDispatch({
        type: "setContent",
        id,
        value: val,
      });
    }
  };

  const handleDelete = (id) => {
    stateDispatch({
      type: "setDelete",
      id,
      value: true,
    });
  };

  const handleHistory = (id) => {
    navigate(`/files/${id}/history`);
  };

  return (
    <div className="Editor">
      <div className="Editor__header">
        <div className="Editor__header-line">
          <div className="Editor__header-info">
            ID: {file.id}
            <br />
            Modified: {printYYYYMMDD(file.modified)}
            <span> · </span>
            Version: {file.version}
          </div>
          <div className="Editor__header-action">
            <Button
              confirm="Confirm to delete"
              onClick={() => handleDelete(id)}
            >
              Delete
            </Button>
          </div>
          <div className="Editor__header-action">
            <Button onClick={() => handleHistory(id)}>History</Button>
          </div>
        </div>
        <div className="Editor__header-line">
          <PluginComponents
            eventName="render.header.inside"
            context={FilesContext}
            dispatch={stateDispatch}
          />
        </div>
      </div>

      <div className="Editor__editor">
        <div className="Editor__editor-inner">
          <MarkdownEditor onChange={handleChange} val={file.data.content} />
        </div>
      </div>
    </div>
  );
};

export default Editor;
