import "./index.css";

function Input({ type, value, onChange, placeholder }) {
  return (
    <div className="input">
      <input
        type={type}
        placeholder={placeholder}
        value={value || ""}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
}

export default Input;
