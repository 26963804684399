import { useContext } from "react";
import { LoginGitHubProvider } from "./LoginGitHubProvider";
import { LoginGithubContext } from "../../store/loginGithub";
import "./index.css";

const Login = () => {
  const [stateGithub] = useContext(LoginGithubContext);

  const isLoggedIn = stateGithub.oAuthToken;

  return (
    <div
      className={`Login Login--${isLoggedIn ? "isLoggedIn" : "isNotLoggedIn"}`}
    >
      {!isLoggedIn && <LoginGitHubProvider />}
    </div>
  );
};

export default Login;
