import React, { useReducer, createContext, useEffect } from "react";
import { merge } from "lodash";
import { mergeText } from "../util/mergeText.js";
import { LOCALSTORE_KEYS } from "../config";

const storedData = JSON.parse(
  localStorage.getItem(LOCALSTORE_KEYS.STORE_FILES) || "{}"
);

const emptyFile = {
  version: 0,
  data: {
    content: "",
  },
  deleted: false,
};

const initialState = {};

const reducer = (state, action) => {
  switch (action.type) {
    case "addFile": {
      return {
        ...state,
        [action.id]: {
          id: action.id,
          ...emptyFile,
          dirty: true,
        },
      };
    }

    case "reset": {
      return {
        ...initialState,
      };
    }

    case "setExtend": {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          data: {
            ...state[action.id].data,
            extend: {
              ...(state[action.id].data.extend || {}),
              [action.ext]: action.value,
            },
          },
          dirty: true,
        },
      };
    }

    case "setContent": {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          data: {
            ...state[action.id].data,
            content: action.value,
          },
          dirty: true,
        },
      };
    }

    case "setDelete": {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          deleted: action.value,
          dirty: true,
        },
      };
    }

    case "updateFile": {
      const finalFile = {
        ...action.new,
      };

      if (action.old) {
        const oldContent = action.old.data.content;
        const newContent = action.new.data.content;
        const currentContent = state[action.id].data.content;
        const finalContent = mergeText(oldContent, newContent, currentContent);
        finalFile.data.content = finalContent;
      }

      return {
        ...state,
        [action.id]: finalFile,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const FilesContext = createContext();

// export const Getter = {
//   // content: (state) => state.data.content,
//   getFile: (state, id) => state.data[id],
// };

export const FilesContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducer,
    merge({
      ...initialState,
      ...storedData,
    })
  );

  useEffect(() => {
    localStorage.setItem(LOCALSTORE_KEYS.STORE_FILES, JSON.stringify(state));
  }, [state]);

  return (
    <FilesContext.Provider value={[state, dispatch]}>
      {children}
    </FilesContext.Provider>
  );
};
