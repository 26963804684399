import { useContext } from "react";
import { useParams } from "react-router-dom";
import { get } from "lodash";
import { registerComponent } from "../componentRegistry";
import { customAlphabet } from "nanoid";

// only letters that are not confusing when printed
const nanoid = customAlphabet(
  "23456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ",
  18,
);

function HeadInline({ context }) {
  const [state, dispatch] = useContext(context);
  const { id } = useParams();

  const file = state[id];

  const handleChange = (event) => {
    const value = event.target.checked;
    let url =
      file.data &&
      file.data.extend &&
      file.data.extend.github &&
      file.data.extend.github.url;

    if (value === true && !url) {
      url = nanoid();
    }

    dispatch({
      type: "setExtend",
      id,
      ext: "github",
      value: {
        ...((file.data.extend && file.data.extend.github) || {}),
        url,
        active: value,
      },
    });
  };

  const handleURLChange = (event) => {
    const value = event.target.value;

    dispatch({
      type: "set",
      id,
      value: {
        ...((file.data.extend && file.data.extend.github) || {}),
        url: value,
      },
    });
  };

  let githubURL =
    file.data.extend && file.data.extend.github && file.data.extend.github.url;
  let githubActive =
    file.data.extend &&
    file.data.extend.github &&
    file.data.extend.github.active;

  return (
    <div className="github-extension">
      Github:
      <input
        type="checkbox"
        checked={githubActive || false}
        onChange={handleChange}
      />
      &nbsp; &nbsp;
      {githubActive && (
        <input type="text" value={githubURL || ""} onChange={handleURLChange} />
      )}
      &nbsp; &nbsp;
      {githubActive && (
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://docs.signalwerk.ch/#/docs/${githubURL}/`}
        >
          show public
        </a>
      )}
    </div>
  );
}

function TitlePrefix({ file }) {
  const isSet = get(file, "data.extend.github.active");

  if (!isSet) {
    return null;
  }

  return <span className="icon-github"></span>;
}

export function register() {
  registerComponent("render.file.preview.header.prefix", TitlePrefix);

  registerComponent("render.header.inside", ({ context, dispatch }) => (
    <div>
      <HeadInline context={context} dispatch={dispatch} />
    </div>
  ));
}
