// github app id
export const GITHUB_CLIENT_ID = "8d03fbcbcd8ab9523bc1";
// export const GITHUB_REDIRECT =
//   process.env.NODE_ENV === "development"
//     ? "http://localhost:3001/redirect"
//     : "https://notzer.signalwerk.workers.dev/redirect"; // after login

export const GITHUB_REDIRECT = "https://notzer.signalwerk.workers.dev"; // after login

export const API_SYNC_INTERVAL_SEC = 60;

export const AUTH_API = "https://notzer.signalwerk.workers.dev";

export const API_DATA =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001"
    : "https://notzer.signalwerk.workers.dev";

export const LOCALSTORE_KEYS = {
  AUTH_GITHUB: "notzer:loginGithub",
  STORE_FILES: "notzer:files",
};
