import { useContext } from "react";
import { useParams } from "react-router-dom";
import { get } from "lodash";
import { registerComponent } from "../componentRegistry";

function HeadInline({ context }) {
  const [state, dispatch] = useContext(context);
  const { id } = useParams();

  const file = state[id];

  const handleChange = (event) => {
    const value = event.target.checked;

    dispatch({
      type: "setExtend",
      id,
      ext: "fav",
      value,
    });
  };

  const isSet = get(file, "data.extend.fav");

  return (
    <div className="fav-extension">
      FAV
      <input type="checkbox" checked={isSet || false} onChange={handleChange} />
    </div>
  );
}

function TitlePrefix({ file }) {
  const isSet = get(file, "data.extend.fav");

  if (!isSet) {
    return null;
  }

  return <span className="small">✨</span>;
}

export const compare = (a, b) => {
  const aFav = a.data.extend?.fav || false;
  const bFav = b.data.extend?.fav || false;

  if (aFav && !bFav) {
    return -1;
  }
  if (!aFav && bFav) {
    return 1;
  }

  return 0;
};

export function register() {
  registerComponent("render.file.preview.sort", compare);

  registerComponent("render.file.preview.header.prefix", TitlePrefix);

  registerComponent("render.header.inside", ({ context, dispatch }) => (
    <div>
      <HeadInline context={context} dispatch={dispatch} />
    </div>
  ));
}
