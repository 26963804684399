import { useContext, useState, useRef, useEffect, useCallback } from "react";
import { FilesContext } from "../../store/files";
import { LoginGithubContext } from "../../store/loginGithub";
import Editor from "../Editor";
import History from "../History";
import Input from "../Input";
import Button from "../Button";
import { FilePreview } from "../FilePreview";
import doSync from "./sync";
import uuid4 from "../../util/uuid4";
import { API_SYNC_INTERVAL_SEC } from "../../config";
import { useInterval } from "../../hooks/useInterval";
import { useMountEffect } from "../../hooks/useMountEffect";
import { useBeforeUnload } from "react-use";
import { useNavigate, useParams, Routes, Route } from "react-router-dom";
import { getComponents } from "../../extensions/componentRegistry";

import "./index.css";

function Files() {
  const [stateGithub, stateGithubDispatch] = useContext(LoginGithubContext);
  const [state, stateDispatch] = useContext(FilesContext);
  const [search, setSearch] = useState(null);

  let navigate = useNavigate();
  const { id } = useParams();
  const detail = id;

  const handleAdd = () => {
    const id = uuid4();
    stateDispatch({ type: "addFile", id });
    navigate(`/files/${id}`);
  };

  let unfilteredFiles = Object.keys(state)
    .map((item) => ({
      ...state[item],
    }))
    .filter((item) => !item.deleted);

  getComponents("render.file.preview.sort").forEach((fn) => {
    unfilteredFiles = unfilteredFiles.sort(fn);
  });

  const files = search
    ? unfilteredFiles.filter(
        (file) =>
          file.data.content &&
          String.prototype.toLowerCase.call(file.data.content).includes(search),
      )
    : unfilteredFiles;

  const isDirty = !!Object.keys(state).find((item) => state[item].dirty);

  const isDirtyRef = useRef();

  useEffect(() => {
    isDirtyRef.current = isDirty;
  });

  // perform initial sync
  useMountEffect(() => {
    console.log("--- initial sync ---");
    doSync(
      state,
      stateDispatch,
      stateGithub.oAuthToken.jwt,
      stateGithub.oAuthToken.token.access_token,
    );
  });

  const dirtyFn = useCallback(() => {
    doSync(
      state,
      stateDispatch,
      stateGithub.oAuthToken.jwt,
      stateGithub.oAuthToken.token.access_token,
    );

    console.log("---sync");
    return isDirtyRef.current;
  }, [
    isDirtyRef,
    state,
    stateDispatch,
    stateGithub.oAuthToken.jwt,
    stateGithub.oAuthToken.token.access_token,
  ]);

  useBeforeUnload(dirtyFn, "You have unsaved changes, are you sure?");

  useInterval(() => {
    doSync(
      state,
      stateDispatch,
      stateGithub.oAuthToken.jwt,
      stateGithub.oAuthToken.token.access_token,
    );
  }, API_SYNC_INTERVAL_SEC * 1000);

  const handleManualSync = () => {
    doSync(
      state,
      stateDispatch,
      stateGithub.oAuthToken.jwt,
      stateGithub.oAuthToken.token.access_token,
    );
  };

  return (
    <div className="Files">
      <div className="Files__list">
        <div className="Files__list_header">
          <div className="Files__list_header-action">
            <Button onClick={() => handleAdd()}>Add</Button>
            <Button onClick={() => handleManualSync()}>Sync</Button>
            <Button
              onClick={() => {
                stateGithubDispatch({ type: "githubOAuthLogout" });

                // reset files state on logout
                stateDispatch({ type: "reset" });
              }}
            >
              Logout
            </Button>
          </div>

          <div className="Files__list_header-search">
            <Input
              type="search"
              value={search || ""}
              placeholder="Search…"
              onChange={(value) => setSearch(value)}
            />
          </div>
        </div>

        <ul className="Files__list-items">
          {files.map((item) => (
            <li key={item.id}>
              <FilePreview file={item} isSelected={item.id === detail} />
            </li>
          ))}
        </ul>
      </div>
      <div className="Files__editor">
        <Routes>
          <Route
            exact
            path="/"
            element={
              <>
                <Editor id={detail} />
              </>
            }
          ></Route>
          <Route exact path="/history" element={<History />}></Route>
        </Routes>
      </div>
    </div>
  );
}

export default Files;
