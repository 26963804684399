import { get } from "lodash";
import fm from "front-matter";
import { replaceVars } from "../../util/replaceVars";
import { PluginComponents } from "../../extensions/componentRegistry";
import { useNavigate } from "react-router-dom";

import "./index.css";

function extractPreview(str, separateTitle = false) {
  const contentLines = str
    .trim()
    .split("\n")
    .map((item) => item.trim())
    .filter((item) => item);

  if (separateTitle) {
    const [header, ...content] = contentLines;
    const text = content.join(" – ").substring(0, 150);
    return { header, text };
  } else {
    const text = contentLines.join(" – ").substring(0, 150);
    return { text };
  }
}

export const FilePreview = ({ file, isSelected }) => {
  const content = replaceVars(file.data.content.trim());
  let header = null;
  let text = null;

  let navigate = useNavigate();

  const setDetail = (id) => {
    navigate(`/files/${id}`);
  };

  try {
    const frontmatter = fm(content);

    const fmHeader = get(frontmatter, "attributes.title");
    const fmBody = get(frontmatter, "body");

    if (fmHeader) {
      header = `${fmHeader} ⸓`;
    }
    text = extractPreview(fmBody).text;
  } catch (e) {
    console.log(e);
  }

  if (!header) {
    const parsed = extractPreview(content, true);

    header = parsed.header;
    text = parsed.text;
  }

  return (
    <button
      className={`FilePreview ${
        file.dirty ? "FilePreview--dirty" : "FilePreview--stored"
      } ${isSelected ? "FilePreview--selected" : "FilePreview--notSelected"}`}
      onClick={() => setDetail(file.id)}
    >
      <h2>
        <PluginComponents
          eventName="render.file.preview.header.prefix"
          file={file}
        />
        {header}
      </h2>
      <p>{text}</p>
    </button>
  );
};
