import { index, add, save, get } from "../../api/Backend";
import { save as githubSave } from "../../api/Github";
import { get as _get } from "lodash";
let api = [];

const doSync = async (state, stateDispatch, jwt, token) => {
  console.log("Start Sync");

  const headers = { Authorization: `Bearer ${jwt}` };

  const files = Object.keys(state).map((item) => ({
    ...state[item],
  }));

  const [filesIndex, error] = await index(headers);

  if (!filesIndex) {
    return;
  }
  if (error) {
    console.error(`Error while getting Serverstate`);
  }

  // new notes from client
  files
    .filter((item) => !api.includes(item.id))
    .filter((item) => item.dirty)
    .filter((item) => item.version === 0)
    .forEach(async (item) => {
      console.log(`client has new files for server (ID: ${item.id})`);
      api.push(item.id);
      const [addedNote, error] = await add(item, headers);

      if (error) {
        console.error(`Error while uploading local File (ID: ${item.id})`);
      }
      if (addedNote) {
        stateDispatch({
          type: "updateFile",
          id: item.id,
          old: item,
          new: addedNote,
        });
      }

      api = api.filter((id) => id !== item.id);
    });

  // client updated notes
  files
    .filter((item) => !api.includes(item.id))
    .filter((item) => item.dirty)
    .forEach(async (item) => {
      console.log(`client has update for server (ID: ${item.id})`);
      api.push(item.id);

      const sha = _get(item, "data.extend.github.content");
      console.log("----- get sha", sha);
      console.log("----- get item", item);

      const response = await githubSave(item, token, sha);

      const content = _get(response, "content.sha");
      const commit = _get(response, "commit.sha");

      console.log("--- updated shas", { content, commit });

      const updatedItem = {
        ...item,
        data: {
          ...item.data,
          extend: {
            ...(item.data.extend || {}),
            github: {
              ...((item.data.extend || {}).github || {}),
              content,
              commit,
            },
          },
        },
      };

      const [storedNote, error] = await save(updatedItem, headers);

      if (error) {
        console.error(
          `Error while updating Server with date from local File (ID: ${item.id})`
        );
      }

      if (storedNote) {
        stateDispatch({
          type: "updateFile",
          id: item.id,
          old: item,
          new: storedNote,
        });
      }

      api = api.filter((id) => id !== item.id);
    });

  // server updated files
  filesIndex.data
    .filter((item) => !api.includes(item.id))
    .forEach(async (remoteCopy) => {
      const localCopy = files.find((file) => file.id === remoteCopy.id);

      // no local copy yet
      // or old local copy
      if (
        !localCopy ||
        (!localCopy.dirty && remoteCopy.version > localCopy.version)
      ) {
        if (!localCopy) {
          console.log(`server has new file for client (ID: ${remoteCopy.id})`);
        } else {
          console.log(
            `server has update for client (ID: ${remoteCopy.id} – Version local: ${localCopy.version} – Version remote: ${remoteCopy.version})`
          );
        }

        api.push(remoteCopy.id);

        const [newFile, error] = await get(
          remoteCopy.id,
          remoteCopy.version,
          headers
        );

        if (error) {
          console.error(
            `Error while update local File from server (ID: ${remoteCopy.id})`
          );
        }

        if (newFile) {
          stateDispatch({
            type: "updateFile",
            id: remoteCopy.id,
            new: newFile,
          });
        }

        api = api.filter((id) => id !== remoteCopy.id);
      }
    });
};

export default doSync;
