// https://github.com/kgoedecke/react-oauth-popup/blob/master/src/index.tsx
import Button from "../../Button";

import { useLocation } from "react-router-dom";

const OauthHandler = ({ url, children, onCode, onClose }) => {
  const onContainerClick = () => {
    window.location.href = url;
  };

  try {
    let location = useLocation();
    console.log("useLocation", location);

    if (location.search) {
      const decUrl = new URL(`http://localhost/${location.search}`);
      console.log("dec", decUrl);
      const code = decUrl.searchParams.get("code");
      const state = decUrl.searchParams.get("state");
      console.log("try code", code);
      console.log("try state", state);

      onCode(code, state);
    }
  } catch (error) {
    console.log(error);
    const currentUrl = window.location.href;
    const params = new URL(currentUrl).searchParams;
    const errorGH = params.get("error");
    console.log("error", errorGH);
  }

  return (
    // eslint-disable-next-line
    <Button
      onClick={() => {
        onContainerClick();
      }}
    >
      {children}
    </Button>
  );
};
export default OauthHandler;
