import React, { useReducer, createContext, useEffect } from "react";
import { LOCALSTORE_KEYS } from "../config";

const storedState = JSON.parse(
  localStorage.getItem(LOCALSTORE_KEYS.AUTH_GITHUB) || "{}"
);

const initialState = {
  oAuthToken: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setGithubOAuthToken": {
      return {
        ...state,
        oAuthToken: action.value,
      };
    }
    case "githubOAuthLogout": {
      return {
        ...state,
        oAuthToken: null,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export const LoginGithubContext = createContext();

export const Getter = {
  oAuthToken: (state) => state.oAuthToken,
};

export const LoginGithubContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    ...storedState,
  });

  useEffect(() => {
    localStorage.setItem(LOCALSTORE_KEYS.AUTH_GITHUB, JSON.stringify(state));
  }, [state]);

  return (
    <LoginGithubContext.Provider value={[state, dispatch]}>
      {children}
    </LoginGithubContext.Provider>
  );
};
