import React, { useState, useContext } from "react";

import { useParams } from "react-router-dom";
import { LoginGithubContext } from "../../store/loginGithub";

import { history as getHistory } from "../../api/Backend";
import Button from "../Button";

const History = ({ demo }) => {
  const { id } = useParams();
  const [stateGithub] = useContext(LoginGithubContext);

  const [history, setHistory] = useState([]);

  const onLoad = async () => {
    const headers = { Authorization: `Bearer ${stateGithub.oAuthToken.jwt}` };

    const [fileHistory, error] = await getHistory(id, headers);

    if (fileHistory) {
      setHistory(fileHistory);
    }
    if (error) {
      console.error(`Error while getting file history`);
    }
  };

  return (
    <div className="History">
      <p>History: {id}</p>
      <p>fetch(`/contents/30ae009f-176a-47ea-87ff-a3ad2940ebe3/history`)</p>
      <Button onClick={onLoad}>load</Button>
      {history.data &&
        history.data.map((item) => (
          <div key={`${item.id}--${item.version}`}>
            <hr />
            <hr />
            !!{item.modified}
            <hr />
            <pre>{item.data && item.data.content}</pre>
            <hr />
            <hr />
          </div>
        ))}
    </div>
  );
};

export default History;
