import "./App.css";

import { useContext } from "react";

import { LoginGithubContext } from "./store/loginGithub";

import { Navigate, Routes, Route } from "react-router-dom";

import Login from "./components/Login";
import Files from "./components/Files";

import { register as RegisterGithub } from "./extensions/github/";
import { register as RegisterFavorite } from "./extensions/fav/";

RegisterFavorite();
RegisterGithub();

function App() {
  const [state] = useContext(LoginGithubContext);

  const isLoggedIn = !!state.oAuthToken;

  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={<Navigate to={isLoggedIn ? "/files" : "/login"} />}
        />
        <Route
          path="/login"
          element={isLoggedIn ? <Navigate to="/files/" /> : <Login />}
        />
        <Route
          path="/files/:id?/*"
          element={
            isLoggedIn ? (
              <>
                <Login />
                <Files />
              </>
            ) : (
              <Navigate to="/login" />
            )
          }
        />
      </Routes>
    </div>
  );
}

export default App;
