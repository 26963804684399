// https://github.com/eea/volto-slate

/*
multiline markdown:
https://slate-js.slack.com/archives/C1RH7AXSS/p1600950386006100


https://gist.github.com/plbabin/fbb561612373a446e27d6209aba096bd

*/

import Prism from "prismjs";
import classNames from "classnames";
import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Slate, Editable, withReact } from "slate-react";
import { Node, Text, createEditor } from "slate";
import { withHistory } from "slate-history";
import "./markdown.css";

// import { css } from "emotion";

Prism.languages.markdownSH = Prism.languages.extend("markup", {});

// https://github.com/PrismJS/prism/blob/master/components/prism-markdown.js
Prism.languages.insertBefore("markdownSH", "prolog", {
  blockquote: { pattern: /^>(?:[\t ]*>)*/m, alias: "punctuation" },
  code: [
    { pattern: /^(?: {4}|\t).+/m, alias: "keyword" },
    { pattern: /``.+?``|`[^`\n]+`/, alias: "keyword" },
  ],

  title: [
    {
      pattern: /\w+.*(?:\r?\n|\r)(?:==+|--+)/,
      alias: "important",
      inside: { punctuation: /==+$|--+$/ },
    },
    {
      pattern: /(^\s*)#+.+/m,
      lookbehind: !0,
      alias: "important",
      inside: { punctuation: /^#+|#+$/ },
    },
  ],
  hr: {
    pattern: /(^\s*)([*-])([\t ]*\2){2,}(?=\s*$)/m,
    lookbehind: !0,
    alias: "punctuation",
  },
  list: {
    pattern: /(^\s*)(?:[*+-–]|\d+\.)(?=[\t ].)/m,
    lookbehind: !0,
    alias: "punctuation",
  },
  "url-reference": {
    pattern:
      /!?\[[^\]]+\]:[\t ]+(?:\S+|<(?:\\.|[^>\\])+>)(?:[\t ]+(?:"(?:\\.|[^"\\])*"|'(?:\\.|[^'\\])*'|\((?:\\.|[^)\\])*\)))?/,
    inside: {
      variable: { pattern: /^(!?\[)[^\]]+/, lookbehind: !0 },
      string: /(?:"(?:\\.|[^"\\])*"|'(?:\\.|[^'\\])*'|\((?:\\.|[^)\\])*\))$/,
      // eslint-disable-next-line no-useless-escape
      punctuation: /^[\[\]!:]|[<>]/,
    },
    alias: "url",
  },
  bold: {
    pattern: /(^|[^\\])(\*\*|__)(?:(?:\r?\n|\r)(?!\r?\n|\r)|.)+?\2/,
    lookbehind: !0,
    inside: { punctuation: /^\*\*|^__|\*\*$|__$/ },
  },
  italic: {
    pattern: /(^|[^\\])([*_])(?:(?:\r?\n|\r)(?!\r?\n|\r)|.)+?\2/,
    lookbehind: !0,
    inside: { punctuation: /^[*_]|[*_]$/ },
  },
  url: {
    pattern:
      /!?\[[^\]]+\](?:\([^\s)]+(?:[\t ]+"(?:\\.|[^"\\])*")?\)| ?\[[^\]\n]*\])/,
    inside: {
      variable: { pattern: /(!?\[)[^\]]+(?=\]$)/, lookbehind: !0 },
      string: { pattern: /"(?:\\.|[^"\\])*"(?=\)$)/ },
    },
  },
});

Prism.languages.markdownSH.bold.inside.url = Prism.util.clone(
  Prism.languages.markdownSH.url,
);

Prism.languages.markdownSH.italic.inside.url = Prism.util.clone(
  Prism.languages.markdownSH.url,
);

Prism.languages.markdownSH.bold.inside.italic = Prism.util.clone(
  Prism.languages.markdownSH.italic,
);

Prism.languages.markdownSH.italic.inside.bold = Prism.util.clone(
  Prism.languages.markdownSH.bold,
);

// prettier-ignore

const serialize = (nodes) => {
  return nodes.map((n) => Node.string(n)).join("\n");
};

const deserialize = (txt) => {
  // if (!txt) {
  //   return [
  //     {
  //       children: [{ text: "" }],
  //     },
  //   ];
  // }
  return txt.split("\n").map((line) => ({
    children: [{ text: line }],
  }));
};

let lastUpdate = null;

const MarkdownEditor = ({ val, onChange }) => {
  const [value, setValue] = useState(deserialize(val));

  const handleChange = (value) => {
    setValue(value);
    const newText = serialize(value);
    if (newText !== lastUpdate) {
      onChange(newText);
      lastUpdate = newText;
    }
  };

  // this is what you need
  useEffect(() => {
    //
    // setValue(deserialize(val));

    if (val !== lastUpdate) {
      setValue(deserialize(val));
    }
  }, [val]);

  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const decorate = useCallback(([node, path]) => {
    const ranges = [];

    if (!Text.isText(node)) {
      return ranges;
    }

    const getLength = (token) => {
      if (typeof token === "string") {
        return token.length;
      } else if (typeof token.content === "string") {
        return token.content.length;
      } else {
        return token.content.reduce((l, t) => l + getLength(t), 0);
      }
    };

    const tokens = Prism.tokenize(node.text, Prism.languages.markdownSH);
    let start = 0;

    for (const token of tokens) {
      // console.log({token})
      const length = getLength(token);
      const end = start + length;

      if (typeof token !== "string" || token !== "multiline") {
        ranges.push({
          [token.type]: true,
          anchor: { path, offset: start },
          focus: { path, offset: end },
        });
      }

      start = end;
    }

    return ranges;
  }, []);

  return (
    <Slate editor={editor} value={value} onChange={handleChange}>
      <Editable
        decorate={decorate}
        renderLeaf={renderLeaf}
        placeholder="Write some markdown..."
        onKeyDown={(event) => {
          if (event.key === "Tab") {
            console.log("run event tabﬂ");

            // Prevent the ampersand character from being inserted.
            event.preventDefault();
            // Execute the `insertText` method when the event occurs.
            editor.insertText("\t");
          }
        }}
      />
    </Slate>
  );
};

const Leaf = ({ attributes, children, leaf }) => {
  //  class definition
  var mdClass = classNames({
    md: true,
    md__bold: leaf.bold,
    md__italic: leaf.italic,
    md__underline: leaf.underline,
    md__title: leaf.title,
    md__list: leaf.list,
    md__hr: leaf.hr,
    md__blockquote: leaf.blockquote,
    md__code: leaf.code,
  });

  return (
    <span {...attributes} className={mdClass}>
      {children}
    </span>
  );
};
// const Leaf = ({ attributes, children, leaf }) => {
//   return (
//     <span
//       {...attributes}
//       className={css`
//         font-weight: ${leaf.bold && "bold"};
//         font-style: ${leaf.italic && "italic"};
//         text-decoration: ${leaf.underlined && "underline"};
//         ${leaf.title &&
//         css`
//           display: inline-block;
//           font-weight: bold;
//           font-size: 20px;
//           margin: 20px 0 10px 0;
//         `}
//         ${leaf.list &&
//         css`
//           padding-left: 10px;
//           font-size: 20px;
//           line-height: 10px;
//         `}
//         ${leaf.hr &&
//         css`
//           display: block;
//           text-align: center;
//           border-bottom: 2px solid #ddd;
//         `}
//         ${leaf.blockquote &&
//         css`
//           display: inline-block;
//           border-left: 2px solid #ddd;
//           padding-left: 10px;
//           color: #aaa;
//           font-style: italic;
//         `}
//         ${leaf.code &&
//         css`
//           font-family: monospace;
//           background-color: #eee;
//           padding: 3px;
//         `}
//       `}
//     >
//       {children}
//     </span>
//   );
// };

export default MarkdownEditor;
