import React from "react";
import { createRoot } from "react-dom/client";
import { HashRouter as Router } from "react-router-dom";

import "./index.css";
import { FilesContextProvider } from "./store/files";
import { LoginGithubContextProvider } from "./store/loginGithub";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root");

const root = createRoot(container); // createRoot(container!) if you use TypeScript

console.log(
  `Last Updated: 2024-07-13 23:02:41
Git Hash: a44527ff
Git Date: 2024-07-14 02:02:06`,
);

root.render(
  <React.StrictMode>
    <LoginGithubContextProvider>
      <FilesContextProvider>
        <Router>
          <App />
        </Router>
      </FilesContextProvider>
    </LoginGithubContextProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
