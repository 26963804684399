export const deltaToString = (operations, inStr) => {
  let str = inStr || "";
  let offset = 0;

  operations.forEach((op) => {
    if (op.insert) {
      // console.log(`str: ${str} – offset: ${offset} – insert: ${op.insert}`)
      str = str.substring(0, offset) + op.insert + str.substring(offset);
      offset += op.insert.length;
    }
    if (op.retain) {
      // console.log(`str: ${str} – offset: ${offset} – retain: ${op.retain}`)
      offset += op.retain;
    }
    if (op.delete) {
      // console.log(`str: ${str} – offset: ${offset} – delete: ${op.delete}`)
      str = str.substring(0, offset) + str.substring(offset + op.delete);
    }
  });
  // console.log(`str: ${str} – offset: ${offset}`)

  return str;
};
