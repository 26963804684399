import Delta from "quill-delta";
import { deltaToString } from "./deltaToString.js";

export const mergeText = (baseContent, currentContent, newContent) => {
  const baseDelta = new Delta([{ insert: baseContent }]);
  const currentDelta = new Delta([{ insert: currentContent }]);
  const newDelta = new Delta([{ insert: newContent }]);

  const diffCurrentContent = baseDelta.diff(currentDelta);
  const diffNewContent = baseDelta.diff(newDelta);
  const diffNewContentFinal = diffCurrentContent.transform(
    diffNewContent,
    true
  );

  const finalDelta = currentDelta.compose(diffNewContentFinal, true);

  return deltaToString(finalDelta);
};
